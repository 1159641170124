export const useScrollToComponent = () => {
    return (blockId: string) => {
        const $block = document.querySelector(blockId)

        if (!$block) return

        const { y } = useWindowScroll({
            behavior: 'smooth',
        })

        y.value = $block.getBoundingClientRect().top - 20
    }
}
